<template>
  <div class="w_material_detail">
    <div class="left_com">
      <div class="top">
        <div class="title">
          <span class="text">{{ data.dto.title }}</span>
          <!-- <span class="btn">
            <img src="../../assets/images/cmf_chouc.png" />
            <span>收藏</span>
          </span> -->
        </div>

        <el-carousel class="imgbox" height="233px">
          <el-carousel-item v-for="(item, index) in data.dto.albums" :key="index">
                  <img :key="index" v-viewer  :src="_this.utils.HandleUrl(item, false, 1)"  height="100" class="item-card">
          </el-carousel-item>
        </el-carousel>

        <!-- <div class="swiper-container imgbox">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(image, index) in data.dto.albums" :key="index">
                <img :src="_this.utils.HandleUrl(image, false, 1)" alt="">
            </div>
          </div>

          <div class="swiper-button-prev"></div>
          <div class="swiper-button-next"></div>
        </div> -->


      </div>

      <div class="bot">
        <div class="desc"><span>详细介绍</span></div>
        <div class="descCon" v-html="_this.utils.HandleContentImg(data.dto.content)"></div>
      </div>
    </div>
    <div class="right_com">
      <div class="lingyu">
        <div class="anli_item">
          <div class="label">行业</div>
          <div class="value">{{ data.dto.anli_hangye ? data.dto.anli_hangye:'--' }}</div>
        </div>
        <div class="anli_item">
          <div class="label">设计师</div>
          <div class="value">{{ data.dto.designer ? data.dto.designer :'--' }}</div>
        </div>
        <div class="anli_item">
          <div class="label">品牌</div>
          <div class="value">{{ data.dto.brand ? data.dto.brand :'--' }}</div>
        </div>
        <div class="anli_item last">
          <div class="label">设计公司</div>
          <div class="value">{{ data.dto.designer_company ? data.dto.designer_company :'--' }}</div>
        </div>
        <div class="parts">
          <div class="parts_item" v-for="(item,index) in data.dto.parts" :key="index">
            <div class="parts_title">{{ item.title }}</div>
            <div class="parts_tips">材料：{{ item.material ? item.material:'--' }}</div>
            <div class="parts_tips">工艺：{{ item.craft ? item.craft :'--' }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import Swiper from "swiper/swiper-bundle.min.js"
import "swiper/swiper-bundle.min.css"
import { useRoute } from 'vue-router';
import { getCurrentInstance, reactive, nextTick } from 'vue';
import { Picture as IconPicture } from '@element-plus/icons-vue'

const instance = getCurrentInstance();
const _this = instance.appContext.config.globalProperties;

const $route = useRoute();
const pro_id = $route.params.id;
const data = reactive({
  dto:{}
});

function GetAnliDetail() {
  var url = "/api/cailiaoguan.ashx?action=GetAnliDetail";
  _this.$http.post(url, { id: pro_id }).then(function (res) {
    if (res.data.status == 1) {
      data.dto = res.data.data;
      data.dto.albums = res.data.data.albums.split(',');
      initSwiper();
    }
  }.bind(this)).catch(function () {
  });
}
GetAnliDetail();


function initSwiper() {
  nextTick(() => {
    new Swiper(".swiper-container", {
      zoom: true,
      slidesPerView: 1,
      centeredSlides: true,
      loop: true,
      autoplay: true,
      pagination: {
        el: '.swiper-pagination',
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    })
  });
}

</script>